import React, { FC, useContext, useEffect, useState } from 'react'
import Modal from '@components/ui/modal'
import Text from '@components/ui/text'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import Attachments from '@components/ui/Attachment'
import { ITimeline, ITimelineActivity } from '@services/types'
import {
	ACTIVITY_TYPE,
	ORIGIN,
	getElectedName
} from '@components/ui/historyCard/timelineBlock'
import * as pageUtils from '@components/ui/historyCard/__index.utils'
import { formatHtmlForStrapiText, formatStrapiText } from '@utils/methods'
import { CATEGORIES } from '@services/constants'

type propsTypes = {
	isModalOpen: boolean
	origin: ORIGIN
	close: () => void
	communication: ITimeline | undefined
}

const InfoModal: FC<propsTypes> = ({ isModalOpen, close, communication }) => {
	const { pageData } = useContext(AppStateContext)

	const getRequestTitleByOverview = (communication: ITimeline | undefined) => {
		if (communication?.activity?.type == ACTIVITY_TYPE.INCIDENT_CREATED)
			return pageData?.assets.myRequest_confirmation_of_a_request_creation
		else if (communication?.activity?.type == ACTIVITY_TYPE.APPOINTMENT) {
			return pageData.assets.myRequestPage_a_summary_of_the_metting_was_added
		} else if (
			communication?.activity?.type == ACTIVITY_TYPE.PHONE_CALL &&
			communication?.from == ORIGIN.CITY
		) {
			return pageData.assets.myRequestPage_phone_call_added_by_laval
		} else if (
			communication?.activity?.type == ACTIVITY_TYPE.PHONE_CALL &&
			communication?.from == ORIGIN.CITIZEN
		) {
			return pageData.assets
				.myRequestPage_you_communicated_with_us_through_phone
		} else if (communication?.activity?.category === CATEGORIES.CANCEL_REQUEST)
			return formatStrapiText(
				pageData?.assets?.you_have_asked_for_the_motion_to_be_rescinded
			)
		else if (
			communication?.activity?.description?.includes(
				'Elle a maintenant le statut « fermé ».'
			)
		)
			return (
				<Text
					content={
						pageData?.assets
							?.label_the_status_of_the_request_has_been_changed_to_closed
					}
				/>
			)
		else if (communication?.activity?.category === CATEGORIES.CREATE_CONSENT) {
			return (
				pageData.assets.You_have_added_a_consent_to_the_elected +
				getElectedName(communication?.activity?.metadata)
			)
		} else if (
			communication?.activity?.category === CATEGORIES.REVOKE_CONSENT
		) {
			return (
				pageData.assets.You_have_revoked_the_consent_of_the_elected +
				getElectedName(communication?.activity?.metadata)
			)
		} else if (
			communication?.activity?.type == ACTIVITY_TYPE.PORTAL_COMMENTS &&
			communication?.activity?.overview?.includes(
				'Nouvelle information en lien avec la requête'
			)
		)
			return formatStrapiText(
				pageData?.assets
					?.myRequest_you_have_communicated_with_us_through_mon_dossier
			)
		else if (
			communication?.activity?.overview?.includes(
				'Votre demande a été prise en charge'
			)
		) {
			return pageData.assets?.myRequest_page_the_request_was_handled
		} else if (
			communication?.activity?.type === ACTIVITY_TYPE.EMAIL &&
			communication.activity?.overview?.match(/^\w*(-\w*)+$/)
		)
			return pageData.requests?.find(
				(req) => req.requestId === communication?.activity?.overview
			)?.title
		else if (
			(communication?.activity?.type == ACTIVITY_TYPE.PORTAL_COMMENTS &&
				origin !== ORIGIN.CITY) ||
			communication?.activity?.category == CATEGORIES.EMAIL
		) {
			if (
				communication?.activity?.type == ACTIVITY_TYPE.PORTAL_COMMENTS &&
				!communication?.activity?.overview?.includes(
					'Votre demande a été prise en charge'
				)
			) {
				return pageData?.assets
					?.myRequest_a_message_was_added_by_la_ville_de_laval
			}

			return `${pageData.assets?.myRequest_the_message} ${
				communication.activity?.overview || ''
			} ${pageData.assets?.myRequest_page_was_added}`
		} else if (
			communication?.activity?.type === ACTIVITY_TYPE.PORTAL_COMMENTS &&
			origin == ORIGIN.CITIZEN
		)
			return formatStrapiText(
				pageData?.assets
					?.myRequest_you_have_added_a_new_information_related_to_the_request
			)
		else if (communication?.activity?.type == ACTIVITY_TYPE.EMAIL)
			return pageData?.assets
				?.myRequest_a_message_was_added_by_la_ville_de_laval

		return communication?.activity?.overview
	}
	const activityDescription = communication?.activity?.description
		?.replace(/(\r\n|\n|\r)/gm, '<br />')
		.replace(/<o:p><\/o:p>/g, '')

	/**
	 * @description This function will open a new window were we can print the content of the modal
	 * @name printContent
	 * @returns void
	 */

	const printContent = () => {
		const newWindow = window.open('', '', 'height=500, width=500')
		newWindow?.document.write('<html>')
		newWindow?.document.write('<body >')
		newWindow?.document.write(
			`<h1>${getRequestTitleByOverview(communication)}</h1>`
		)
		newWindow?.document.write(activityDescription || '')
		newWindow?.document.write('</body></html>')
		newWindow?.document.close()
		newWindow?.print()
	}

	const addTargetBlankToAnchorTag = (html?: string) => {
		if (!html) return
		const parser = new DOMParser()
		const parsedHtml = parser.parseFromString(html, 'text/html')
		const anchorTags = parsedHtml.querySelectorAll('a')
		anchorTags.forEach((tag) => tag.setAttribute('target', '_blank'))
		return parsedHtml.documentElement.innerHTML
	}

	// This function adds anchor tags to a text that contain links without anchor tags
	// while preserving other links an images
	const insertAnchorTag = (text?: string): string | undefined => {
		if (!text) return undefined
		const linkRegex = /(https?:\/\/[^\s]+)/g
		const anchorTagRegex = /<a\b[^>]*>(.*?)<\/a>/g
		const imageTagRegex = /<img\b[^>]*>/g

		let replacedText = text

		const linkMatches = text.match(linkRegex)
		if (linkMatches) {
			linkMatches.forEach((link) => {
				// Verifys if the current link is within an anchor tag
				const isWithinAnchorTag = replacedText
					.match(anchorTagRegex)
					?.some((tag) => tag.includes(link))
				// Verifys if the current link is within an image tag
				const isWithinImageTag = replacedText
					.match(imageTagRegex)
					?.some((tag) => tag.includes(link))

				if (!isWithinAnchorTag && !isWithinImageTag) {
					const anchorTag = `<a href="${link}">${link}</a>`
					//   inserts an anchor tag to the link
					replacedText = replacedText.replace(link, anchorTag)
				}
			})
		}

		return replacedText
	}

	return (
		<Modal
			title={getRequestTitleByOverview(communication)}
			cancelButtonText={pageData?.assets?.close_label}
			primaryButtonText={pageData?.assets?.button_print}
			onPrimaryButton={printContent}
			showExitBtn
			isOpen={isModalOpen}
			onCancelBtn={() => {
				close()
			}}
			onSetIsOpen={() => {}}
			swapButtonPositions
			alignBtn={'left'}
		>
			<div className={pageUtils.classes.modalContent}>
				<div
					dangerouslySetInnerHTML={{
						__html: addTargetBlankToAnchorTag(activityDescription) ?? ''
					}}
				/>
				{!!communication?.activity?.attachments?.length && (
					<Attachments
						showHeader={false}
						files={communication?.activity?.attachments!}
					/>
				)}
			</div>
		</Modal>
	)
}

export default InfoModal
