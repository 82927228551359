import React, { FC, useContext, useState } from 'react'
import Notification from '@components/ui/notification'
import { RequestStatusEnum } from '@services/constants'
import { Request } from '@services/models'
import * as pageUtils from '@components/ui/TNRequestDetails/__index.utils'
import { getStatusBadgeLabel } from '@components/ui/requestCard'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import * as pageRequestDetailsUtils from '@pages/auth/requests/__[id].utils'
import { joinClasses } from '@utils/styles'
import ButtonWithTooltip from '../buttonWithTooltip'
import HistoryCard from '../historyCard'
import AddInformationModal from '../addInformationModal'
import { MODAL } from '@pages/auth/requests/__[id].utils'
import CancelRequestModal from '../cancelRequestModal'
import { cancelWhiteIcon, plusWhiteIcon } from '@images/icons'
import { Icon } from '../Icon'

type propsType = {
	request: Request
	isModalOpen: string
	isOldRequest: boolean
	onCloseModal: () => void
	onToggleModal: (value: string) => void
	onInformationAdded: (consentId: any) => void
}

const PlainRequestDetails: FC<propsType> = ({
	request,
	children,
	isModalOpen,
	isOldRequest,
	onCloseModal,
	onToggleModal,
	onInformationAdded
}) => {
	const [isInformationSent, setIsInformationSent] = useState<boolean>(false)
	const { pageData } = useContext(AppStateContext)

	return (
		<>
			<div className={pageRequestDetailsUtils.classes.row2}>
				{isInformationSent && (
					<Notification
						showExitBtn
						text={`${pageData.assets?.myRequest_information_well_added_to_the_request} <b>${request?.code}</b>`}
						type="success"
						hasHtml
						onClickCancelBtn={() => {
							setIsInformationSent(false)
						}}
					/>
				)}

				<div
					className={joinClasses([
						pageUtils.classes.container,
						pageUtils.classes.noMargin
					])}
					style={{ overflow: 'hidden' }}
				>
					<div className={pageUtils.classes.section}>
						<div className={pageRequestDetailsUtils.classes.col12}>
							{isOldRequest && (
								<Notification
									text={pageData?.assets?.myRequestPage_mon_dossier_warning}
									type="info"
								/>
							)}

							{isOldRequest && request?.caseOrigin == 3 && (
								<Notification
									text={
										pageData?.assets
											?.myRequestPage_the_description_of_this_request_may_have_been_modified_to_add_additional_detail
									}
									type="info"
								/>
							)}
						</div>

						<div
							className={joinClasses([
								pageUtils.classes.bloc,
								pageUtils.classes.borderBottom,
								pageUtils.classes.noMarginBottom
							])}
						>
							<div className={pageUtils.classes.actionRow}>
								<div className={pageUtils.classes.status}>
									<div
										className={`${pageUtils.classes.tag} ${
											pageUtils.classes[
												request?.cancelReason
													? RequestStatusEnum.CLOSED
													: request?.status ?? RequestStatusEnum.PROCESSING
											]
										}`}
									>
										{getStatusBadgeLabel(
											pageData.assets,
											request?.cancelReason
												? RequestStatusEnum.CLOSED
												: request?.status ?? RequestStatusEnum.PROCESSING
										)}
									</div>
								</div>

								<div className={pageUtils.classes.actionButtons}>
									<button
										className={joinClasses([
											pageUtils.classes.button,
											pageUtils.classes.buttonWithIcon
										])}
										onClick={() => onToggleModal(MODAL.ADD_INFORMATION)}
									>
										<Icon src={plusWhiteIcon} />
										{pageData?.assets?.page_requestDetails_add_an_information}
									</button>
									{!request?.cancelReason &&
										request?.status !== RequestStatusEnum.CLOSED && (
											<button
												className={joinClasses([
													pageUtils.classes.button,
													pageUtils.classes.buttonWithIcon
												])}
												onClick={() => onToggleModal(MODAL.CANCEL)}
											>
												<Icon src={cancelWhiteIcon} />
												{pageData?.assets?.button_cancel}
											</button>
										)}
								</div>
							</div>

							<div
								style={{ marginTop: '1.5rem' }}
								className={pageUtils.classes.informationLine}
							>
								<span className={pageUtils.classes.infoQuestion}>
									<b>
										{
											pageData?.assets
												?.request_form_confirmation_request_number_text
										}
									</b>
								</span>
								<span className={pageUtils.classes.infoAnswer}>
									{request?.code}
								</span>
							</div>
							<div className={pageUtils.classes.informationLine}>
								<span className={pageUtils.classes.infoQuestion}>
									<b>{pageData?.assets?.label_creationDate}</b>
								</span>
								<span className={pageUtils.classes.infoAnswer}>
									{request?.dateAdd?.localDateAndTimeString}
								</span>
							</div>
							<div className={pageUtils.classes.informationLine}>
								<span className={pageUtils.classes.infoQuestion}>
									<b>{pageData?.assets?.label_transmission_mode}</b>
								</span>
								<span className={pageUtils.classes.infoAnswer}>Web</span>
							</div>
						</div>

						<div
							className={joinClasses([
								pageUtils.classes.bloc,
								pageUtils.classes.historyCard
							])}
						>
							<HistoryCard history={request?.timeline} />
						</div>
					</div>

					<div className={pageUtils.classes.section}>{children}</div>
				</div>

				<AddInformationModal
					setModal={onCloseModal}
					request={request!}
					isOpen={isModalOpen === MODAL.ADD_INFORMATION}
					onInformationAdded={onInformationAdded}
				/>
				<CancelRequestModal
					onCancel={onCloseModal}
					isOpen={isModalOpen == MODAL.CANCEL}
					request={request}
				/>
			</div>
		</>
	)
}

export default PlainRequestDetails
