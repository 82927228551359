import React, { FC, useEffect, useState } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import {
	AdministrativeRiviewInfo,
	CheckboxList,
	RadioList,
	SelectList
} from '@utils/request'
import Modal from '../modal'
import { formatStrapiText } from '@utils/methods'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import * as formUtils from '@components/requestForms/__forms.utils'
import moment from 'moment'

type Classes = {
	linkButton: string
	underlineOnHover: string
	description: string
	tableSummary: string
	rowNumber: string
}

const classes: Classes = makeClasses({
	underlineOnHover: {
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	linkButton: {
		marginLeft: '0px',
		padding: '0px',
		backgroundColor: 'transparent',
		fontSize: '14px',
		border: `none`,
		color: `${Colors.secondary}`,
		'&:hover': {
			backgroundColor: 'transparent !important',
			border: `none !important`,
			color: `${Colors.secondary} !important`
		}
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0 8px',
		padding: 0,
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		marginBottom: '10px!important'
	},
	tableSummary: {
		borderCollapse: 'collapse',
		width: '100%',
		'& td, & th': {
			fontSize: '16px',
			border: '1px solid #ccc',
			padding: '7px 15px',
			lineHeight: '28px'
		},
		'& tr:nth-child(even)': {
			backgroundColor: Colors.lightGrey4
		}
	},
	rowNumber: {
		fontWeight: '500',
		color: Colors.white,
		borderRadius: '50%',
		fontSize: '14px',
		width: '26px',
		height: '26px',
		lineHeight: '26px',
		background: Colors.lightBlue,
		textAlign: 'center',
		display: 'block'
	}
})

const PermitOccupationOfPublicHighwayDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request,
	language
}) => {
	const [openDetailModal, setOpenDetailModal] = useState<boolean>(false)
	const [selectedDaysList, setSelectedDaysList] = useState<SelectList[]>([])
	const [question10ListType, setQuestion10ListType] = useState<CheckboxList[]>()
	const [question15_3ListType, setQuestion15_3ListType] =
		useState<CheckboxList[]>()

	const radioListIsNewRequest: RadioList[] = [
		{
			label: pageAssets?.label_new_request,
			value: '102720000',
			key: 'label_new_request'
		},
		{
			label: pageAssets?.label_request_to_modify,
			value: '102720001',
			key: 'label_request_to_modify'
		}
	]
	const radioListCompanyOrOrganisation: RadioList[] = [
		{
			label: pageAssets?.request_form_company_or_organisation,
			value: '102720000',
			key: 'request_form_company_or_organisation'
		},
		{
			label: pageAssets?.request_form_individual,
			value: '102720001',
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_non_profit_organization,
			value: '102720002',
			key: 'request_form_non_profit_organization'
		}
	]
	const radioListQuestion15: RadioList[] = [
		{
			label: pageAssets?.label_complete,
			value: 'true',
			key: 'label_complete'
		},
		{
			label: pageAssets?.label_partial,
			value: 'false',
			key: 'label_partial'
		}
	]
	const radioListQuestion21: RadioList[] = [
		{
			label: pageAssets?.label_yes_the_company_installs_it,
			value: '102720000',
			key: 'label_yes_the_company_installs_it'
		},
		{
			label: pageAssets?.label_no_the_work_is_subcontracted,
			value: '102720001',
			key: 'label_no_the_work_is_subcontracted'
		}
	]
	const radioListQuestion27: RadioList[] = [
		{
			label: pageAssets?.label_continuous_occupation,
			value: '102720000',
			key: 'label_continuous_occupation'
		},
		{
			label: pageAssets?.label_occasional_occupation,
			value: '102720001',
			key: 'label_occasional_occupation'
		}
	]
	const question15_4ListType: RadioList[] = [
		{
			label: pageAssets?.request_form_3_meters_or_less,
			key: 'request_form_3_meters_or_less',
			value: REQUEST_CONFIGS_KEYS.approximateWidthOfObstruction.meters3
		},
		{
			key: 'request_form_3_to_6_meters',
			value: REQUEST_CONFIGS_KEYS.approximateWidthOfObstruction.meters3To6,
			label: pageAssets?.request_form_3_to_6_meters
		},
		{
			key: 'request_form_6_to_9_meters',
			value: REQUEST_CONFIGS_KEYS.approximateWidthOfObstruction.meters6To9,
			label: pageAssets?.request_form_6_to_9_meters
		},
		{
			key: 'request_form_9_to_12_meters',
			value: REQUEST_CONFIGS_KEYS.approximateWidthOfObstruction.meters9To12,
			label: pageAssets?.request_form_9_to_12_meters
		},
		{
			key: 'request_form_more_than_12_meters',
			value: REQUEST_CONFIGS_KEYS.approximateWidthOfObstruction.meters12,
			label: pageAssets?.request_form_more_than_12_meters
		}
	]
	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const natureOfInventionList = formUtils.addLabelToList(
		formUtils.natureOfInventionListTypeAndValues,
		pageAssets
	)
	const impactsOnTrafficList = formUtils.addLabelToList(
		formUtils.impactsOnTrafficListTypeAndValues,
		pageAssets
	)

	const selectedDays = formUtils.addLabelToList(
		formUtils.selectedDaysListTypeAndValues,
		pageAssets
	) as SelectList[]

	useEffect(() => {
		setQuestion10ListType(natureOfInventionList)
		setQuestion15_3ListType(impactsOnTrafficList)
		setSelectedDaysList(selectedDays)
	}, [language])

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const questionAnswer = (question: string, answer?: string) => {
		if (answer) {
			return (
				<>
					<div>
						<strong>{question}</strong>
						<br />
						<span>{answer}</span>
					</div>
					<br />
				</>
			)
		}
	}

	const renderSubSection4Summary = () => {
		return (
			<>
				{questionAnswer(
					pageAssets?.request_form_occupation_date,
					getLabelFromList(
						radioListQuestion27,
						request.incidentForm?.occupationDate
					)
				)}

				{request.incidentForm?.occupationDate ==
					radioListQuestion27[0].value && (
					<>
						{questionAnswer(
							pageAssets?.request_form_fireHydrantsTempAqueductNetwork_startDate_label,
							pageUtils.getLocaleDate(
								request.incidentForm?.occupationStartDate
							) ?? ''
						)}
						{questionAnswer(
							pageAssets?.request_form_fireHydrantsTempAqueductNetwork_endDate_label,
							pageUtils.getLocaleDate(
								request.incidentForm?.occupationEndDate
							) ?? ''
						)}
					</>
				)}

				{request.incidentForm?.occupationDate ==
					radioListQuestion27[1].value && (
					<>
						<div>
							<strong>{pageAssets?.label_selected_dates}</strong>
							<br />
							<>
								<button
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button,
										classes.linkButton,
										classes.underlineOnHover
									])}
									onClick={() => {
										setOpenDetailModal(true)
									}}
									type="button"
								>
									{formatStrapiText(pageAssets?.label_see_details)}
								</button>
							</>

							<Modal
								title={pageAssets?.label_date + '(s)'}
								isOpen={openDetailModal}
								onCloseModal={() => setOpenDetailModal(false)}
								onSetIsOpen={setOpenDetailModal}
								showExitBtn
							>
								{request.incidentForm?.dateTimeList ? (
									<>
										{request.incidentForm?.dateTimeList?.map((data, index) => {
											return (
												<div
													key={`${data?.start_time}-${index}`}
													style={{ marginBottom: '10px' }}
												>
													{data?.date}
												</div>
											)
										})}
									</>
								) : (
									<p className={classes.description}>
										{pageAssets?.label_no_selected_date}
									</p>
								)}
							</Modal>
						</div>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_occupation_hours}
						</h5>

						<div style={{ overflowX: 'auto', margin: '40px 0px' }}>
							<table className={classes.tableSummary}>
								<thead>
									<tr>
										<th></th>
										<th>
											<strong>{pageAssets?.label_date + 's'}</strong>
										</th>
										<th>
											<strong>{pageAssets?.label_start_hour}</strong>
										</th>
										<th>
											<strong>{pageAssets?.label_end_hour}</strong>
										</th>
										<th>
											<strong>{pageAssets?.label_24_on_24}</strong>
										</th>
									</tr>
								</thead>

								<tbody>
									{formUtils
										.transformData(request.incidentForm?.dateTimeList)
										?.map((dateTime, index) => {
											return (
												<tr key={`dateTime${index}`}>
													<td>
														<span className={classes.rowNumber}>
															{index + 1}
														</span>
													</td>
													<td>
														{
															pageAssets[
																formUtils.transformData(
																	request.incidentForm?.dateTimeList
																).length == 1
																	? formUtils.selectedDaysListTypeAndValues[1]
																			.key
																	: dateTime.occpation_value
															]
														}
													</td>
													<td>{dateTime.start_time}</td>
													<td>{dateTime.end_time}</td>
													<td>
														{getLabelFromList(radioListYesNo, dateTime.is_24_7)}
													</td>
												</tr>
											)
										})}
								</tbody>
							</table>
						</div>
					</>
				)}
				<br />
				{questionAnswer(
					pageAssets?.request_form_any_additional_information,
					request.incidentForm?.additionalOccupationInformation
				)}
			</>
		)
	}

	const getLabelsFromString = (
		list: formUtils.CheckboxType[],
		answer?: string
	): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.key)
	}

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_identification_du_demandeur}
			</h4>
			<h5 className={pageUtils.classes.h5}>
				{pageAssets.request_form_type_de_demandes}
			</h5>
			{request.incidentForm?.isNewRequest && (
				<>
					{questionAnswer(
						pageAssets?.request_form_is_this_a_new_request,
						getLabelFromList(
							radioListIsNewRequest,
							request.incidentForm?.isNewRequest?.toString()
						)?.replace(/\*\*/g, '')
					)}
				</>
			)}

			{request.incidentForm?.isNewRequest?.toString() ==
				radioListIsNewRequest[1].value && (
				<>
					{questionAnswer(
						pageAssets?.request_form_permit_number,
						request.incidentForm?.permitNumber
					)}
					{request.incidentForm?.requestNumber
						? questionAnswer(
								pageAssets?.request_form_request_number,
								request.incidentForm?.requestNumber
						  )
						: questionAnswer(
								pageAssets?.request_form_request_number,
								request.incidentForm?.requestNumberOther
						  )}
					{request.incidentForm?.occupationDate && (
						<>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_occupation_period}
							</h4>
							{renderSubSection4Summary()}
						</>
					)}
				</>
			)}

			{request.incidentForm?.isNewRequest?.toString() ==
				radioListIsNewRequest[0].value && (
				<>
					{request.incidentForm?.isNewRequest?.toString() ==
						radioListIsNewRequest[0].value && (
						<>
							<h5 className={pageUtils.classes.h5}>
								{pageAssets?.label_applicant}
							</h5>
							{questionAnswer(
								pageAssets?.request_form_making_request_as_a,
								getLabelFromList(
									radioListCompanyOrOrganisation,
									request.incidentForm?.makingRequestAs
								)
							)}
							{request.incidentForm?.makingRequestAs?.toString() ==
								radioListCompanyOrOrganisation[1].value && (
								<>
									{questionAnswer(
										pageAssets?.request_form_have_you_contracted_a_company,
										getLabelFromList(
											radioListYesNo,
											request.incidentForm?.haveContractedCompany
										)
									)}
								</>
							)}
							{request.incidentForm?.makingRequestAs?.toString() !=
								radioListCompanyOrOrganisation[1].value && (
								<>
									{questionAnswer(
										pageAssets?.request_form_is_there_an_agreement_with_the_city,
										getLabelFromList(
											radioListYesNo,
											request.incidentForm?.haveAgreementWithCity
										)
									)}
									{questionAnswer(
										pageAssets?.request_form_are_the_works_to_be_carried,
										getLabelFromList(
											radioListYesNo,
											request.incidentForm?.workFollowInsurance
										)
									)}

									{request.incidentForm?.workFollowInsurance?.toString() ==
										radioListYesNo[0].value && (
										<>
											{questionAnswer(
												pageAssets?.request_form_connection_permit_number,
												request.incidentForm?.connectionPermitNumber
											)}
											{questionAnswer(
												pageAssets?.request_form_name_of_the_city_of_laval,
												request.incidentForm?.nameOfLavalCity
											)}
										</>
									)}

									<h5 className={pageUtils.classes.h5}>
										{pageAssets?.request_form_information_about_company}
									</h5>

									{questionAnswer(
										pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
										request.incidentForm?.companyInChargeInfo?.organization_name
									)}
									{questionAnswer(
										pageAssets?.request_form_company_address,
										request.incidentForm?.companyInChargeInfo?.address
									)}
									{request.incidentForm?.personInchargeOfProject &&
										questionAnswer(
											pageAssets?.request_form_person_incharge_of_project,
											pageAssets?.request_form_i_am_the_person_incharge_of_project
										)}

									<>
										{questionAnswer(
											pageAssets?.label_firstName,
											request.incidentForm?.companyInChargeInfo?.first_name
										)}
										{questionAnswer(
											pageAssets?.label_lastName,
											request.incidentForm?.companyInChargeInfo?.last_name
										)}
										{questionAnswer(
											pageAssets?.label_email,
											request.incidentForm?.companyInChargeInfo?.email
										)}
										{questionAnswer(
											pageAssets?.label_telephone,
											request.incidentForm?.companyInChargeInfo?.telephone
										)}
									</>

									{questionAnswer(
										pageAssets?.request_form_are_you_the_supervisor,
										getLabelFromList(
											radioListYesNo,
											request.incidentForm?.isSupervisor
										)
									)}

									{request.incidentForm?.isSupervisor?.toString() ==
										radioListYesNo[1].value && (
										<>
											{questionAnswer(
												pageAssets?.label_firstName,
												request.incidentForm?.supervisorInfo?.first_name
											)}
											{questionAnswer(
												pageAssets?.label_lastName,
												request.incidentForm?.supervisorInfo?.last_name
											)}
											{questionAnswer(
												pageAssets?.label_email,
												request.incidentForm?.supervisorInfo?.email
											)}
											{questionAnswer(
												pageAssets?.label_telephone,
												request.incidentForm?.supervisorInfo?.telephone
											)}
										</>
									)}
								</>
							)}
						</>
					)}

					{/* Step 2 */}
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_reason_for_occupation}
					</h4>
					<br />
					<div>
						{request.incidentForm?.natureOfIntervention && (
							<>
								<strong>
									{pageAssets?.request_form_nature_of_the_intervention}
								</strong>
								<br />

								{getLabelsFromString(
									formUtils.natureOfInventionListTypeAndValues,
									request.incidentForm?.natureOfIntervention
								).map((answerLabel, index) => {
									return (
										<>
											<span
												key={`${answerLabel}-${index}-natureOfInterventionType`}
											>
												{pageAssets[answerLabel]}
											</span>
											<br />
										</>
									)
								})}
							</>
						)}
						{request.incidentForm?.natureOfInterventionOther
							?.split(', ')
							.map((answer, index) => {
								return (
									<>
										<span key={`${answer}-${index}-natureOfIntervention`}>
											{answer}
										</span>
										<br />
									</>
								)
							})}
					</div>
					{questionAnswer(
						pageAssets?.request_form_additional_information_description,
						request.incidentForm?.additionalInformation
					)}
					{/* Step 3 */}
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_the_location_of_the_occupation}
					</h4>
					<h5 className={pageUtils.classes.h5}>
						{pageAssets?.page_requestInfo_location}
					</h5>
					{questionAnswer(
						pageAssets?.request_form_main_address_of_work,
						request.incidentForm?.mainAddressOfWork
					)}

					{questionAnswer(
						pageAssets?.request_form_start_of_the_disruption,
						request.incidentForm?.startOfTheDisruption
					)}
					{questionAnswer(
						pageAssets?.request_form_end_of_the_disruption,
						request.incidentForm?.endOfTheDisruption
					)}

					{questionAnswer(
						pageAssets?.request_form_any_additional_information,
						request.incidentForm?.additionalDisruptionInformation
					)}

					<h5 className={pageUtils.classes.h5}>
						{pageAssets?.request_form_maitaining_circulation}
					</h5>
					{questionAnswer(
						pageAssets?.request_form_is_full_or_partial_closure,
						getLabelFromList(
							radioListQuestion15,
							request.incidentForm?.isFullOrPatialClosure
						)
					)}

					{request.incidentForm?.isFullOrPatialClosure?.toString() ==
						radioListQuestion15[1].value && (
						<>
							{questionAnswer(
								pageAssets?.request_form_will_the_road_occupancy_be_limited,
								getLabelFromList(
									radioListYesNo,
									request.incidentForm?.willRoadBeLimited
								)
							)}
						</>
					)}
					{/* 15.3 */}
					{request.incidentForm?.willRoadBeLimited?.toString() ==
						radioListYesNo[1].value && (
						<>
							<strong>
								{pageAssets?.request_form_what_are_the_other_impacts}
							</strong>
							<br />
							{getLabelsFromString(
								formUtils.impactsOnTrafficListTypeAndValues,
								request.incidentForm?.impactsOnTraffic?.toString()
							).map((answerLabel, index) => {
								return (
									<>
										<span
											key={`${answerLabel}-${index}-natureOfInterventionType`}
										>
											{pageAssets[answerLabel]}
										</span>
										<br />
									</>
								)
							})}
						</>
					)}
					<br />
					<>
						{questionAnswer(
							pageAssets?.request_form_what_is_the_approximate_width,
							getLabelFromList(
								question15_4ListType,
								request.incidentForm?.approximateWidthOfObstruction
							)
						)}
					</>
					{/* )} */}
					{/* 15.5 */}
					{request.incidentForm?.willRoadBeLimited?.toString() ==
						radioListYesNo[0].value && (
						<>
							{questionAnswer(
								pageAssets?.request_form_what_is_the_approximate_length,
								request.incidentForm?.approximateLengthOfObstruction + 'm'
							)}
							{questionAnswer(
								pageAssets?.request_form_does_the_occupation_comply_with_the_requirements,
								getLabelFromList(
									radioListYesNo,
									request.incidentForm?.occupancyRespectingExigences
								)
							)}
						</>
					)}
					{/* 15.6 */}
					<>
						{questionAnswer(
							pageAssets?.request_form_are_you_planning_to_use_a_standardized,
							getLabelFromList(
								radioListYesNo,
								request.incidentForm?.useStandardizedBoard
							)
						)}
					</>

					{/* 15.6.1 if yes */}
					{request.incidentForm?.useStandardizedBoard?.toString() ===
						radioListYesNo[0].value && (
						<>
							{questionAnswer(
								pageAssets?.request_form_number_of_the_standardized_drawing,
								request.incidentForm?.numberOfStandardizedDrawing
							)}
						</>
					)}

					{/* 16 */}
					{questionAnswer(
						pageAssets?.request_form_is_the_obstruction_area_located_near,
						getLabelFromList(
							radioListYesNo,
							request.incidentForm?.isLocatedNearIntersection
						)
					)}
					{/* 17 */}
					{questionAnswer(
						pageAssets?.request_form_will_the_obstruction_area_allow,
						getLabelFromList(
							radioListYesNo,
							request.incidentForm?.willAllowPassage
						)
					)}
					{/* 18 */}
					{questionAnswer(
						pageAssets?.request_form_will_the_obstruction_area_make_an_STL,
						getLabelFromList(
							radioListYesNo,
							request.incidentForm?.willMakeAnSTL
						)
					)}
					{/* 19 */}
					{questionAnswer(
						pageAssets?.request_form_is_a_temporary_reduction,
						getLabelFromList(
							radioListYesNo,
							request.incidentForm?.isATemporaryReduction
						)
					)}
					{questionAnswer(
						pageAssets?.request_form_any_additional_information,
						request.incidentForm?.anyAdditionalInformation
					)}

					{request.incidentForm?.companyResponsibleForSignage?.toString() && (
						<>
							<h5 className={pageUtils.classes.h5}>
								{pageAssets?.request_form_signage}
							</h5>
							{questionAnswer(
								pageAssets?.request_form_is_company_responsible_for_signage,
								getLabelFromList(
									radioListQuestion21,
									request.incidentForm?.companyResponsibleForSignage?.toString()
								)
							)}
						</>
					)}
					{/* 21 */}

					{/* {request.incidentForm?.makingRequestAs?.toString() ==
						radioListCompanyOrOrganisation[2].value && ( */}
					{/* <>
						{questionAnswer(
							pageAssets?.request_form_is_company_responsible_for_signage,
							getLabelFromList(
								radioListQuestion21,
								request.incidentForm?.companyResponsibleForSignage?.toString()
							)
						)}
					</> */}
					{/* )} */}
					{/* {request.incidentForm?.companyResponsibleForSignage?.toString() ==
						radioListQuestion21[1].value && ( */}
					<>
						{questionAnswer(
							pageAssets?.request_form_historicalArchive_organizationName_label,
							request.incidentForm?.signalingResponsibleCompany
								?.organization_name
						)}
						{questionAnswer(
							pageAssets?.label_name_of_the_responsible_person,
							request.incidentForm?.signalingResponsibleCompany?.first_name
						)}
						{questionAnswer(
							pageAssets?.label_email,
							request.incidentForm?.signalingResponsibleCompany?.email
						)}
						{questionAnswer(
							pageAssets?.label_telephone,
							request.incidentForm?.signalingResponsibleCompany?.telephone
						)}
					</>
					{/* )} */}
					{request.incidentForm?.isNewRequest?.toString() !=
						radioListIsNewRequest[1].value && (
						<>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_occupation_period}
							</h4>
							{renderSubSection4Summary()}
						</>
					)}
				</>
			)}
		</>
	)
}

export default PermitOccupationOfPublicHighwayDetails
