import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RadioList, SelectList } from '@utils/request'
import { formatStrapiText } from '@utils/methods'
import TableDetailsModal from '../modals/tableDetailsModal'

const AnnualRegistrationCertificateForContractorsFormDetails: FC<pageUtils.Props> =
	({ pageAssets, request }) => {
		const radioListYesNo: RadioList[] = [
			{
				label: pageAssets.request_form_select_yes,
				value: 'true',
				key: 'request_form_select_yes'
			},
			{
				label: pageAssets.request_form_select_no,
				value: 'false',
				key: 'request_form_select_no'
			}
		]

		const getLabelFromList = (list: SelectList[], value) => {
			return list?.find((element) => element.value == value?.toString())?.label
		}

		const [isFirstModalOpen, setIsFirstModalOpen] = useState<boolean>(false)
		const [isSecondModalOpen, setIsSecondModalOpen] = useState<boolean>(false)

		return (
			<>
				<h4 className={pageUtils.classes.h4}>
					{pageAssets.request_form_company_information}
				</h4>

				<p className={pageUtils.classes.paragraph}>
					<b>
						{
							pageAssets.request_form_annualRegistrationCertificateForContractors_companyName_label
						}
					</b>
					<br />
					{request.incidentForm?.business?.last_name}
				</p>

				<p className={pageUtils.classes.paragraph}>
					<b>
						{
							pageAssets.request_form_annualRegistrationCertificateForContractors_quebecBusinessNumber_label
						}
					</b>
					<br />
					{request.incidentForm?.quebecBusinessNumber}
				</p>

				<p className={pageUtils.classes.paragraph}>
					<b>
						{
							pageAssets.request_form_annualRegistrationCertificateForContractors_representativeName_label
						}
					</b>
					<br />
					{request.incidentForm?.representativeName}
				</p>

				<p className={pageUtils.classes.paragraph}>
					<b>
						{
							pageAssets.request_form_annualRegistrationCertificateForContractors_representativeTitle_label
						}
					</b>
					<br />
					{request.incidentForm?.representativeTitle}
				</p>

				{/* ---------------------------------------- */}

				<h4 className={pageUtils.classes.h4}>
					{pageAssets.request_form_company_details}
				</h4>

				<p className={pageUtils.classes.paragraph}>
					<b>{pageAssets.label_address}</b>
					<br />
					{request.incidentForm?.business?.address}
					<br />
					{request.incidentForm?.business?.city}{' '}
					{/* // @TODO: ajouter `state` dans l'objet IApplicant  */}
					{/* {' (QC) '}´´ */}
					{!!request.incidentForm?.business?.postal_code &&
						request.incidentForm?.business?.postal_code}{' '}
					{request.incidentForm?.business?.state}{' '}
					{request.incidentForm?.business?.country}
					<br />
					{request.incidentForm?.business?.appartment
						? `${formatStrapiText(pageAssets?.label_apartment_office)} ${
								request.incidentForm?.business?.appartment
						  }`
						: ''}
				</p>

				<p className={pageUtils.classes.paragraph}>
					<b>{pageAssets.label_email}</b>
					<br />
					{request.incidentForm?.business?.email}
				</p>
				<p className={pageUtils.classes.paragraph}>
					<b>{pageAssets.label_telephone}</b>
					<br />
					{request.incidentForm?.business?.telephone}
				</p>

				{/* ---------------------------------------- */}

				<h4 className={pageUtils.classes.h4}>
					{
						pageAssets.request_form_annualRegistrationCertificateForContractors_secondSubStep_title
					}
				</h4>

				<p className={pageUtils.classes.paragraph}>
					<b>{pageAssets.request_form_isSameInformations_label}</b>
					<br />
					{getLabelFromList(
						radioListYesNo,
						request.incidentForm?.isSameInformations
					)}
				</p>

				{request.incidentForm?.isSameInformations == false && (
					<>
						<p className={pageUtils.classes.paragraph}>
							<b>
								{
									pageAssets.request_form_annualRegistrationCertificateForContractors_permitHolderName
								}
							</b>
							<br />
							{request.incidentForm?.permitHolder?.last_name}
						</p>

						<p className={pageUtils.classes.paragraph}>
							<b> {pageAssets.label_address} </b>
							<br />
							{request.incidentForm?.permitHolder?.address}
							{request.incidentForm?.permitHolder?.appartment && (
								<>
									<br />
									{formatStrapiText(pageAssets?.label_apartment_office)}{' '}
									{request.incidentForm?.permitHolder?.appartment}
								</>
							)}
						</p>

						<p className={pageUtils.classes.paragraph}>
							<b> {pageAssets.label_email} </b>
							<br />
							{request.incidentForm?.permitHolder?.email}
						</p>

						<p className={pageUtils.classes.paragraph}>
							<b> {pageAssets.label_telephone} </b>
							<br />
							{request.incidentForm?.permitHolder?.telephone}
						</p>
					</>
				)}

				<p className={pageUtils.classes.paragraph}>
					<b>
						{
							pageAssets.request_form_annualRegistrationCertificateForContractors_permitLicense_label
						}
					</b>
					<br />
					{request.incidentForm?.permitLicenseNumber}
				</p>
				<p className={pageUtils.classes.paragraph}>
					<b>
						{
							pageAssets.request_form_annualRegistrationCertificateForContractors_permitExpiryDate_label
						}
					</b>
					<br />
					{pageUtils.getLocaleDate(request.incidentForm?.permitExpiryDate!)}
				</p>

				{/* -------------------------------------------------------- */}

				<h4 className={pageUtils.classes.h4}>
					{
						pageAssets.request_form_annualRegistrationCertificateForContractors_thirdSubStep_title
					}
				</h4>

				<p>
					<span
						onClick={() => {
							setIsFirstModalOpen(true)
						}}
						className={pageUtils.classes.viewDetailsSpan}
					>
						<b>{pageAssets.request_form_show_details}</b>
					</span>
				</p>

				<TableDetailsModal
					isModalOpen={isFirstModalOpen}
					close={() => {
						setIsFirstModalOpen(false)
					}}
					data={request?.incidentForm?.pesticideCertificatesList}
					title={
						pageAssets.request_form_annualRegistrationCertificateForContractors_thirdSubStep_title
					}
					headers={[
						pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesCertificateHolder_label,
						pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesNumber_label,
						pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesCategory_label,
						pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesIssueDate_label,
						pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesExpirationDate_label
					]}
					attributes={[
						{ name: 'holder_name', type: 'string' },
						{ name: 'certificat_number', type: 'string' },
						{ name: 'category', type: 'string' },
						{ name: 'issue_date', type: 'date' },
						{ name: 'expiration_date', type: 'date' }
					]}
				/>

				{/* -------------------------------------------------------- */}

				<h4 className={pageUtils.classes.h4}>
					{
						pageAssets.request_form_annualRegistrationCertificateForContractors_fourthSubStep_title
					}
				</h4>

				<p>
					<span
						onClick={() => {
							setIsSecondModalOpen(true)
						}}
						className={pageUtils.classes.viewDetailsSpan}
					>
						<b>{pageAssets.request_form_show_details}</b>
					</span>
				</p>

				<TableDetailsModal
					isModalOpen={isSecondModalOpen}
					close={() => {
						setIsSecondModalOpen(false)
					}}
					data={request?.incidentForm?.vehicleInformationList}
					title={
						pageAssets.request_form_annualRegistrationCertificateForContractors_fourthSubStep_title
					}
					headers={[
						pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationBrand_label,
						pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationModel_label,
						pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationYear_label,
						pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationColor_label,
						pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationRegistration_label,
						pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationOnCompanyName_label
					]}
					attributes={[
						{ name: 'brand', type: 'string' },
						{ name: 'model', type: 'string' },
						{ name: 'year', type: 'string' },
						{ name: 'color', type: 'string' },
						{ name: 'registration_number', type: 'string' },
						{ name: 'business_identified', type: 'list', list: radioListYesNo }
					]}
				/>
			</>
		)
	}

export default AnnualRegistrationCertificateForContractorsFormDetails
